import { takeEvery, put, take } from "redux-saga/effects";
import Router from "next/router";
import * as Sentry from '@sentry/browser';
import { takeRight, isEqual } from 'lodash';
import { loginActions } from "../login";
import { userInformationTypes } from "../userInformation";

function* checkForFailure({ type, error }) {
  if (type && type.toLowerCase().endsWith("failure")) {
    const { statusCode, status } = error;
    if (statusCode === 8008) {
      yield put(loginActions.logout());
      Router.push("/");
    }
    if(status === 900)
      Router.push('/maintenance');
  }
}

function* handleFailure() {
  yield takeEvery("*", checkForFailure);
}

// function* checkDuplicates() {
//   let prevCalls = [];
//   while (true) {
//     const action = yield take('*');
//     if (prevCalls.some((prevAction) => isEqual(action, prevAction))) {
//       Sentry.captureMessage(`Duplicate MessageCall ${action?.type}`, action);
//     }
//     prevCalls = takeRight([...prevCalls, action], 3);
//   }
// }

export default {
  handleFailure,
  // checkDuplicates
};
