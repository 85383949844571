import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/browser';
import reducer from './reducer';
import runSagas from './runSagas';

const logger = store => next => action => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    console.group(action.type);
    console.info('dispatching', action);
  }
  const result = next(action);
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    console.log('next state', store.getState());
    console.groupEnd();
  }
  return result;
};

const sentryLogger = () => next => action => {
  const { type, ...props } = action;
  Sentry.addBreadcrumb({ category: 'action', message: `Dispatching ${type}`, data: props });
  const result = next(action);
  return result;
};

export default function initializeStore(initialState, { isServer }) {
  const sagaMiddleware = createSagaMiddleware();
  if (isServer) {
    const store = createStore(
      reducer,
      initialState,
      applyMiddleware(sentryLogger, logger, sagaMiddleware)
    );
    return store;
  }
  const { persistStore, persistReducer } = require('redux-persist');
  const autoMergeLevel2 = require('redux-persist/es/stateReconciler/autoMergeLevel2').default;
  //  storage/default ist localstorage
  const storage = require('redux-persist/lib/storage/').default;

  const persistConfig = {
    key: 'nextjs',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: 'queues',
  };
  const persistedReducer = persistReducer(persistConfig, reducer);
  const store = createStore(
    persistedReducer,
    initialState,
    applyMiddleware(sentryLogger, logger, sagaMiddleware)
  );
  store.persistor = persistStore(store);
  runSagas(sagaMiddleware);
  return store;
}
