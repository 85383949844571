import { call, spawn, all, take, cancel, fork } from 'redux-saga/effects';
import { values } from 'lodash';
import { profilesSagas } from '../profiles';
import { profileSagas } from '../profile';
import { loginSagas, loginTypes } from '../login';
import { tokenSagas } from '../token';
import { swipesSagas } from '../swipes';
import { presentsSagas } from '../presents';
import { searchsettingsSagas } from '../searchsettings';
import { visitorSagas } from '../visitors';
import { paymentSagas } from '../payment';
import { galleriesSagas } from '../galleries';
import { galleryItemsSagas } from '../galleryItems';
import { conversationsSagas } from '../conversations';
import { messagesSagas } from '../messages';
import { friendsSagas } from '../friends';
import { searchSagas } from '../search';
import { myGallerySagas } from '../myGallery';
import { pushNotificationsSagas } from '../pushNotifications';
import { conversationMessagesSagas } from '../conversationMessages';
import { matchesSagas } from '../matches';
import { configurationSagas } from '../configuration';
import { userInformationSagas } from '../userInformation';
import { profileNearMeSagas } from '../profilesNearMe';
import { failureSagas } from '../failure';
import { visitedSagas } from '../visited';
import { likesSagas } from '../likes';
import { blockedUserSagas } from '../blockedUser';

const sagas = [
  profilesSagas,
  profileSagas,
  loginSagas,
  tokenSagas,
  swipesSagas,
  visitorSagas,
  paymentSagas,
  galleriesSagas,
  galleryItemsSagas,
  presentsSagas,
  searchsettingsSagas,
  conversationsSagas,
  conversationMessagesSagas,
  messagesSagas,
  friendsSagas,
  searchSagas,
  myGallerySagas,
  pushNotificationsSagas,
  matchesSagas,
  configurationSagas,
  userInformationSagas,
  profileNearMeSagas,
  failureSagas,
  visitedSagas,
  likesSagas,
  blockedUserSagas
].reduce((array, item) => [...array, ...values(item)], []);

function* sagaProtection(saga) {
  try {
    yield call(saga);
  } catch (error) {
    console.warn(error);
  }
}

function* sagaSpawn(saga) {
  while (true) {
    const sagaFork = yield fork(sagaProtection, saga);
    yield take(loginTypes.logout);
    yield cancel(sagaFork);
  }
}

function* rootSaga() {
  yield all(sagas.map(saga => spawn(sagaSpawn, saga)));
}

export default function runSagas(middleware) {
  middleware.run(rootSaga);
}
