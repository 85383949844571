import types from './types';

const reducer = (state = null, action) => {
  if (action.type.endsWith('FAILURE')) {
    return action.error;
  }
  if (action.type === types.resetError)
    return null;
  return state;
};

export default reducer;
