import { combineReducers } from 'redux';
import profiles from '../profiles';
import profile from '../profile';
import login, { loginTypes } from '../login';
import token from '../token';
import swipes from '../swipes';
import presents from '../presents';
import searchsettings from '../searchsettings';
import visitors from '../visitors';
import payment from '../payment';
import conversations from '../conversations';
import galleryItems from '../galleryItems';
import galleries from '../galleries';
import messages from '../messages';
import friends from '../friends';
import search from '../search';
import myGallery from '../myGallery';
import pushNotifications from '../pushNotifications';
import error from '../error';
import globalAlert from '../alert';
import conversationMessages from '../conversationMessages';
import matches from '../matches';
import configuration from '../configuration';
import notifications from '../notifications';
import profilesNearMe from '../profilesNearMe';
import visited from '../visited';
import likes from '../likes';
import blocked from '../blockedUser';

const reducer = combineReducers({
  profiles,
  user: combineReducers({
    profile,
    token,
    searchsettings,
    gallery: myGallery
  }),
  login,
  swipes,
  visitors,
  presents,
  payment,
  conversations,
  galleries,
  galleryItems,
  messages,
  friends,
  search,
  pushNotifications,
  error,
  globalAlert,
  conversationMessages,
  matches,
  configuration,
  notifications,
  profilesNearMe,
  visited,
  likes,
  blocked
});

const coreReducer = (state, action) => {
  const stateValue = action.type === loginTypes.logout ? undefined : state;
  return reducer(stateValue, action);
};

export default coreReducer;
